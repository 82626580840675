import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import {
  IDetailedTableData,
  IExpenseAccounts,
  IIdentifiers,
  IVendors,
} from "./DetailsTableViewController.web";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface ITableData {
  [key: string]: string | number | Date | null;
}

export interface IDetailedResponseData {
  attributes: IDetailedTableData;
}

interface S {
  sidebarNavWidth: number;
  accountsData: ITableData[];
  isOpenDetailedUploadScreen: boolean;
  isOpenDetailTableView: boolean;
  detailedSheetData: IDetailedTableData[];
  loader: boolean;
  isOpenInconsistencyPage: boolean;
  missingVendorIds: string[];
  missingIdentifierIds: string[];
  missingExpenseAccountIds: string[];
  vendorObjectList: { name: string; value: string }[];
  identifierObjectList: { name: string; value: string }[];
  expenseAccountObjectList: { name: string; value: string }[];
  isOpenDetailedUploadView: boolean;
  monthListData: string[];
  isAllScreenLoaded: boolean;
}

interface SS {}

export default class DetailsController extends BlockComponent<Props, S, SS> {
  getDetailedSheetDataApiId: string = "";
  getIdentifierObjectListApiId: string = "";
  getVendorObjectListApiId: string = "";
  getExpenseAccountObjectListApiId: string = "";
  getMonthsApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      sidebarNavWidth: 100,
      accountsData: [],
      isOpenDetailedUploadScreen: false,
      isOpenDetailTableView: false,
      detailedSheetData: [],
      loader: true,
      isOpenInconsistencyPage: false,
      missingIdentifierIds: [],
      missingExpenseAccountIds: [],
      missingVendorIds: [],
      vendorObjectList: [],
      identifierObjectList: [],
      isOpenDetailedUploadView: false,
      monthListData: [],
      expenseAccountObjectList: [],
      isAllScreenLoaded: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson?.errors &&
      responseJson.errors?.length > 0 &&
      responseJson.errors[0]?.token
    ) {
      this.navigateToHomePage();
    }

    if (apiRequestCallId === this.getDetailedSheetDataApiId) {
      if (responseJson?.data && responseJson.data?.length > 0) {
        const detailedData: IDetailedTableData[] = responseJson.data.map(
          (item: IDetailedResponseData) => item.attributes
        );
        this.setState({
          isOpenDetailTableView: true,
          isOpenDetailedUploadScreen: false,
          accountsData: [],
          detailedSheetData: detailedData,
          isOpenInconsistencyPage: false,
          isAllScreenLoaded: true,
        });
      } else {
        this.getIdentifierObjectList();
      }
    }

    if (apiRequestCallId === this.getIdentifierObjectListApiId) {
      this.setIdentifierListResponse(responseJson);
    }

    if (apiRequestCallId === this.getVendorObjectListApiId) {
      this.setVendorListResponse(responseJson);
    }

    if (apiRequestCallId === this.getExpenseAccountObjectListApiId) {
      this.setExpenseAccountListResponse(responseJson);
    }

    if (apiRequestCallId === this.getMonthsApiId) {
      if (responseJson?.months?.length > 0) {
        const currentDate = moment();
        const updatedMonths = responseJson.months.filter((month: string) =>
          moment(month, "MMMM YYYY").isSameOrBefore(currentDate)
        );
        this.setState({ monthListData: updatedMonths }, () => {
          this.getDetailedSheetData(
            this.state.monthListData[this.state.monthListData.length - 1]
          );
        });
      } else {
        this.getIdentifierObjectList();
      }
    }
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getMonthList();
  }

  setVendorListResponse = (responseJson: { data: IVendors[] }) => {
    if (responseJson?.data && responseJson.data?.length > 0) {
      this.setState({
        vendorObjectList: responseJson.data.map((vItem: IVendors) => ({
          name: vItem.attributes.vendor_name,
          value: vItem.id,
        })),
      });
      this.getExpenseAccountObjectList();
    } else {
      this.setState({ loader: false, isAllScreenLoaded: true });
    }
  };

  setIdentifierListResponse = (responseJson: { data: IIdentifiers[] }) => {
    if (responseJson?.data && responseJson.data?.length > 0) {
      this.setState({
        identifierObjectList: responseJson.data.map((iItem: IIdentifiers) => ({
          name: iItem.attributes.prepaid_account_identifier,
          value: iItem.id,
        })),
      });
      this.getVendorObjectList();
    } else {
      this.setState({ loader: false, isAllScreenLoaded: true });
    }
  };

  setExpenseAccountListResponse = (responseJson: {
    data: IExpenseAccounts[];
  }) => {
    if (responseJson?.data && responseJson.data?.length > 0) {
      this.setState({
        expenseAccountObjectList: responseJson.data.map(
          (vItem: IExpenseAccounts) => ({
            name: vItem.attributes.exp_account,
            value: vItem.id,
          })
        ),
        isOpenDetailTableView: false,
        isOpenDetailedUploadScreen: true,
        loader: false,
        isOpenInconsistencyPage: false,
        isAllScreenLoaded: true,
      });
    } else {
      this.setState({ loader: false, isAllScreenLoaded: true });
    }
  };

  onSidebarNavWidthChange = (showWideSidebar: boolean) => {
    this.setState({ sidebarNavWidth: showWideSidebar ? 241 : 100 });
  };

  setAccountsData = (accData: ITableData[]) => {
    this.setState({ accountsData: accData });
  };

  redirectToDetailedUploadScreen = async () => {
    this.setState({
      isOpenDetailedUploadScreen: true,
      isOpenDetailedUploadView: false,
      isOpenInconsistencyPage: false,
      isOpenDetailTableView: false,
      accountsData: [],
      detailedSheetData: [],
    });
    await setStorageData("isFirstLoad", "true");
  };

  backToInitialFileUpload = async () => {
    await setStorageData("redirectToImportPage", true);
    this.setState({ accountsData: [] });
  };

  backToDetailsUpload = () => {
    this.setState({
      isOpenDetailedUploadScreen: true,
      isOpenDetailedUploadView: true,
      isOpenInconsistencyPage: false,
      isOpenDetailTableView: false,
      accountsData: [],
    });
  };

  emptyDetailsTable = () => {
    this.setState({
      isOpenDetailTableView: true,
      isOpenDetailedUploadScreen: false,
      accountsData: [],
      isOpenInconsistencyPage: false,
      detailedSheetData: [
        {
          ...configJSON.emptyRowObject,
          amortization_start: moment(moment(), "MMM 'YY").format("MM/YY"),
        },
      ],
    });
    setStorageData("startFromScratch", "true");
  };

  navigateToHomePage = () => {
    const navMsgObject = new Message(getName(MessageEnum.NavigationMessage));
    navMsgObject.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    navMsgObject.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navMsgObject);
  };

  openInconsistencyPage = (
    vendorList: string[],
    identifierList: string[],
    expenseAccountList: string[]
  ) => {
    this.setState({
      loader: false,
      isOpenInconsistencyPage: true,
      missingVendorIds: vendorList,
      missingIdentifierIds: identifierList,
      missingExpenseAccountIds: expenseAccountList,
      isAllScreenLoaded: true
    });
  };

  getDetailedSheetData = async (month: string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const detailedSheetDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailedSheetDataApiId = detailedSheetDataRequestMsg.messageId;

    detailedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentMonthsDetailsDataApiEndPoint + month
    );

    detailedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    detailedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      detailedSheetDataRequestMsg.id,
      detailedSheetDataRequestMsg
    );
  };

  getVendorObjectList = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getVendorObjectListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorObjectListApiId = getVendorObjectListRequestMsg.messageId;

    getVendorObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorListApiEndPoint
    );

    getVendorObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getVendorObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      getVendorObjectListRequestMsg.id,
      getVendorObjectListRequestMsg
    );
  };

  getIdentifierObjectList = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getIdentifierObjectListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentifierObjectListApiId =
      getIdentifierObjectListRequestMsg.messageId;

    getIdentifierObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentifierListApiEndPoint
    );

    getIdentifierObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getIdentifierObjectListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      getIdentifierObjectListRequestMsg.id,
      getIdentifierObjectListRequestMsg
    );
  };

  getExpenseAccountObjectList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const expenseAccountObjectsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseAccountObjectListApiId =
      expenseAccountObjectsRequestMsg.messageId;

    expenseAccountObjectsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseAccountsApiEndPoint
    );

    expenseAccountObjectsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    expenseAccountObjectsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      expenseAccountObjectsRequestMsg.id,
      expenseAccountObjectsRequestMsg
    );
  };

  getMonthList = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getMonthsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthsApiId = getMonthsDataRequestMsg.messageId;

    getMonthsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getMonthsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMonthsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getMonthsDataRequestMsg.id, getMonthsDataRequestMsg);
  };

  toggleLoader = (isLoad: boolean) => {
    this.setState({ loader: isLoad });
  };
}
