import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@material-ui/core";
const dateIcon = require("./date-icon.svg");
const dateIconWhite = require("./date-icon-white.svg");

const theme = createTheme({
  palette: {
    primary: {
      main: "#01AA7F",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: "5px",
    paddingLeft: (props: IDatePickerProps) => (props.selectedDate ? 64 : 52),
    [theme.breakpoints.down("md")]: {
      paddingLeft: (props: IDatePickerProps) => (props.selectedDate ? 44 : 36),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: (props: IDatePickerProps) => (props.selectedDate ? 12 : 0),
    },
  },
  popup: {
    "& .MuiPickersCalendarHeader-iconButton": {
      color: "#01AA7F !important",
    },
  },
}));

const StyledDatePicker = styled(KeyboardDatePicker)({
  "& .MuiInput-root": {
    backgroundColor: "#002348",
    borderRadius: "8px",
    height: "46px",
    width: "240px",
    display: "flex",
    flexDirection: "row-reverse",
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
  },
  "& .MuiInput-underline::before": {
    content: "",
    border: "1px solid #B4B4B4",
    borderRadius: "8px",
    height: "46px",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    border: "1px solid #B4B4B4",
    height: "46px",
  },
  "& .MuiInput-underline::after": {
    content: "",
    border: "1px solid #B4B4B4",
    borderRadius: "8px",
    transition: "0s",
    height: "46px",
  },
  "& .MuiInput-underline.Mui-focused::after": {
    border: "1px solid #B4B4B4",
    transition: "0s",
    height: "46px",
  },
});

interface IDatePickerProps {
  selectedDate: Moment | null;
  handleDateChange: (date: Moment | null) => void;
  minDate?: Moment| null;
  maxDate?: Moment| null;
}

const CustomDatePicker: React.FC<IDatePickerProps> = ({
  selectedDate,
  handleDateChange,
  minDate,
  maxDate,
}: IDatePickerProps) => {
  const classes = useStyles({ selectedDate, handleDateChange, minDate, maxDate });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const openDatePicker: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closeDatePickerOnBlur = () => {
    setAnchorEl(null);
  };

  const onDateChange = (date: Moment | null) => {
    setAnchorEl(null);
    handleDateChange(date);
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledDatePicker
          disableToolbar
          data-test-id="date-picker"
          variant="inline"
          format="MM/DD/YY"
          margin="normal"
          label=""
          value={selectedDate}
          initialFocusedDate={maxDate}
          onChange={onDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
            className: classes.iconButton,
            size: "small",
          }}
          minDate={minDate}
          maxDate={maxDate}
          keyboardIcon={
            <img
              src={selectedDate ? dateIconWhite : dateIcon}
              alt="date"
              height={16}
            />
          }
          onClick={openDatePicker}
          placeholder="Select Date"
          autoOk
          PopoverProps={{
            classes: {
              paper: classes.popup,
            },
            anchorEl: anchorEl,
            open: Boolean(anchorEl),
            onClose: closeDatePickerOnBlur,
          }}
          InputProps={{
            readOnly: true
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
