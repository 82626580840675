import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
  TextField,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomSearchableDropdown from "./CustomSearchableDropdown.web";

const useStyles = makeStyles((theme) => ({
  table: {
    overflowX: "auto",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: `calc(100vw - 96px)`,
      [theme.breakpoints.down("xs")]: {
        maxWidth: `calc(100vw - 16px)`,
      },
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: ICustomSummaryTableProps) =>
        `calc(100vw - ${props.width}px)`,
    },
    "& .table-body-cell": {
      backgroundColor: "#171717",
      color: "#FFF",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "0px",
      minWidth: "160px",
      maxWidth: "180px",
      height: "48px",
    },
    "& .table-head-cell": {
      backgroundColor: "#171717",
      color: "#FFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 16px",
      minWidth: "160px",
      maxWidth: "270px",
    },
  },
  textField: {
    height: "100%",
    "& textarea": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
    },
    "& input": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "12px 24px",
      "& fieldset": {
        border: "2px solid transparent",
        borderRadius: "0px !important",
      },
      "&:hover fieldset": {
        border: "2px solid #00AED2 !important",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #00AED2 !important",
      },
      "&.Mui-disabled fieldset": {
        border: "2px solid transparent !important",
      },
      "&.Mui-error fieldset": {
        border: "2px solid #DC2626 !important",
      },
    },
  },
}));

interface IRawData {
  id?: number;
  vendor_name_id?: number;
  vendor_name?: string;
  prepaid_account_identifier_id?: number;
  prepaid_account_identifier?: string;
  invoice_number?: string;
  amortization_period?: string;
  amortization_start?: string;
  amortization_end?: string;
  total_amount?: string;
  adjustment_amount?: string;
  notes?: string;
}

interface ICustomSummaryTableProps {
  columns: {
    name: string;
    showSorting: boolean;
    key: keyof IRawData;
  }[];
  rows: IRawData[];
  width: number;
  onSort?: (column: keyof IRawData, isAsc: boolean) => void;
  prepaidAccountIdentifierList: { name: string; value: string }[];
  vendorList: { name: string; value: string }[];
  sortedColumn: { name: string; isAsc: boolean };
}

const CustomSummaryTable: React.FC<ICustomSummaryTableProps> = ({
  columns,
  rows,
  width,
  onSort,
  prepaidAccountIdentifierList,
  vendorList,
  sortedColumn,
}) => {
  const classes = useStyles({
    columns,
    rows,
    width,
    onSort,
    prepaidAccountIdentifierList,
    vendorList,
    sortedColumn,
  });

  const handleSort = (column: {
    name: string;
    showSorting: boolean;
    key: keyof IRawData;
  }) => {
    if (column.showSorting) {
      if (
        !sortedColumn.name ||
        sortedColumn.name !== column.key ||
        (sortedColumn.name === column.key && !sortedColumn.isAsc)
      ) {
        onSort && onSort(column.key, true);
      } else if (sortedColumn.name === column.key && sortedColumn.isAsc) {
        onSort && onSort(column.key, false);
      }
    }
  };

  return (
    <Box className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell className="table-head-cell" key={column.name}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => handleSort(column)}
                  style={
                    column.showSorting
                      ? webStyle.pointer
                      : webStyle.defaultPointer
                  }
                >
                  <Box width={"max-content"} padding={"0px 4px"}>
                    {column.name}
                  </Box>
                  {column.showSorting && (
                    <Box>
                      {!(
                        sortedColumn.name === column.key && !sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.key, true)}
                          data-test-id="asc-sort"
                        >
                          <ExpandLessIcon />
                        </Box>
                      )}
                      {!(
                        sortedColumn.name === column.key && sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.key, false)}
                          data-test-id="desc-sort"
                        >
                          <ExpandMoreIcon />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData: IRawData | any, rowIndex: number) => (
            <TableRow key={`${rowData?.id}-${rowIndex}-row`}>
              {columns.map((columnRow, colIndex: number) => (
                <TableCell
                  align="center"
                  className={"table-body-cell"}
                  key={columnRow.key + colIndex + "table-cell"}
                >
                  {(columnRow.key === "vendor_name" ||
                    columnRow.key === "prepaid_account_identifier") && (
                    <Box>
                      <CustomSearchableDropdown
                        value={rowData[columnRow.key]}
                        name=""
                        error={false}
                        options={
                          columnRow.key === "vendor_name"
                            ? vendorList
                            : prepaidAccountIdentifierList
                        }
                        handleChange={(value: string, id: string) => {}}
                        isDark={true}
                        disabled={true}
                        deleteOption={(option: string) => {}}
                        updateOption={(value: string, optionId: string) => {}}
                      />
                    </Box>
                  )}
                  {columnRow.key !== "prepaid_account_identifier" &&
                    columnRow.key !== "vendor_name" && (
                      <Box>
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          value={
                            rowData[columnRow.key] !== undefined &&
                            rowData[columnRow.key] !== null &&
                            rowData[columnRow.key]
                              ? columnRow.key === "total_amount" ||
                                columnRow.key === "adjustment_amount"
                                ? `$${rowData[columnRow.key]}`
                                : rowData[columnRow.key]
                              : ""
                          }
                          multiline
                          fullWidth
                          disabled
                        />
                      </Box>
                    )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const webStyle = {
  pointer: {
    cursor: "pointer",
  },
  defaultPointer: {
    cursor: "default",
  },
};

export default CustomSummaryTable;
