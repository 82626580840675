import moment, { Moment } from "moment";

export const getSidebarToggle = () => {
  const toggle = window.localStorage.getItem("toggleSidebar");
  return toggle ? Boolean(JSON.parse(toggle)) : true;
};

export const clearStorage = () => {
  window.localStorage.clear();
};

export const getFileSize = (size: number) => {
  if (size < 1024) {
    return size + " B";
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  }
};

export const reducedFileName = (filename: string) => {
  if (filename.length <= 15) {
    return filename;
  } else {
    const extInd = filename.lastIndexOf(".");
    const name = filename.substring(0, extInd);
    const extension = filename.substring(extInd);
    const truncatedName = name.substring(0, 15 - extension.length - 3);
    return truncatedName + "..." + extension;
  }
};

export const handleSort = (
  column: string,
  isAsc: boolean,
  dataArray: { [key: string]: string | number | Date | boolean | null }[]
) => {
  const direction = isAsc ? 1 : -1;
  return dataArray.sort((a, b) => {
    const valueA = a[column];
    const valueB = b[column];

    if (valueA === null && valueB !== null) {
      return -direction;
    }
    if (valueA !== null && valueB === null) {
      return direction;
    }
    if (valueA === null && valueB === null) {
      return 0;
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.localeCompare(valueB) * direction;
    }
    if (typeof valueA === "boolean" && typeof valueB === "boolean") {
      return (valueA === valueB ? 0 : valueA ? 1 : -1) * direction;
    }
    if (valueA instanceof Date && valueB instanceof Date) {
      return (valueA.getTime() - valueB.getTime()) * direction;
    }
    if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * direction;
    }
    return 0;
  });
};

export interface IDetailedTableData {
  id?: number;
  images: { name: string; url: string | File | null; id: number }[];
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_date: string;
  invoice_number: string;
  invoice_description: string;
  check: string;
  amortization_period: string;
  amortization_start: string;
  amortization_end: string;
  expense_account: string;
  exp_account_id: number;
  total_amount: string;
  expense_amount: string;
  notes: string;
  account_id: number;
  time_of_creation: string;
  edit?: boolean;
  error?: string[];
  remaining_balance: number;
}

export const handleDetailedTableSort = (
  column: keyof IDetailedTableData,
  isAsc: boolean,
  dataArray: IDetailedTableData[]
) => {
  const direction = isAsc ? 1 : -1;
  return dataArray.sort((a, b) => {
    const valueA = a[column];
    const valueB = b[column];

    if (valueA === null && valueB !== null) {
      return -direction;
    }
    if (valueA !== null && valueB === null) {
      return direction;
    }
    if (valueA === null && valueB === null) {
      return 0;
    }

    const parseDateMMYY = (dateStr: string): Date => {
      const parts = dateStr.split("/");
      if (parts.length === 2) {
        const month = parseInt(parts[0], 10);
        const year = parseInt(`20${parts[1]}`, 10);
        return new Date(year, month - 1);
      }
      return new Date(dateStr);
    };

    if (
      valueA &&
      valueB &&
      (column === "invoice_date" ||
        column === "amortization_start" ||
        column === "amortization_end")
    ) {
      const date1 = parseDateMMYY(valueA.toString());
      const date2 = parseDateMMYY(valueB.toString());
      return (date1.getTime() - date2.getTime()) * direction;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.localeCompare(valueB) * direction;
    }
    if (typeof valueA === "boolean" && typeof valueB === "boolean") {
      return (valueA === valueB ? 0 : valueA ? 1 : -1) * direction;
    }
    if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * direction;
    }
    return 0;
  });
};

export interface ISummaryTableRow {
  id?: number;
  vendor_name_id?: number;
  vendor_name?: string;
  prepaid_account_identifier_id?: number;
  prepaid_account_identifier?: string;
  invoice_number?: string;
  amortization_period?: string;
  amortization_start?: string;
  amortization_end?: string;
  total_amount?: string;
  adjustment_amount?: string;
  notes?: string;
}

export const handleSummaryTableSort = (
  column: keyof ISummaryTableRow,
  isAsc: boolean,
  dataArray: ISummaryTableRow[]
) => {
  const direction = isAsc ? 1 : -1;
  return dataArray.sort((a, b) => {
    const valueA = a[column];
    const valueB = b[column];

    if (valueA === null && valueB !== null) {
      return -direction;
    }
    if (valueA !== null && valueB === null) {
      return direction;
    }
    if (valueA === null && valueB === null) {
      return 0;
    }

    const parseDateMMYY = (dateStr: string): Date => {
      const parts = dateStr.split("/");
      if (parts.length === 2) {
        const month = parseInt(parts[0], 10);
        const year = parseInt(`20${parts[1]}`, 10);
        return new Date(year, month - 1);
      }
      return new Date(dateStr);
    };

    if (
      valueA &&
      valueB &&
      (column === "amortization_start" || column === "amortization_end")
    ) {
      const date1 = parseDateMMYY(valueA.toString());
      const date2 = parseDateMMYY(valueB.toString());
      return (date1.getTime() - date2.getTime()) * direction;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.localeCompare(valueB) * direction;
    }
    if (
      (column === "adjustment_amount" || column === "total_amount") &&
      valueA &&
      valueB
    ) {
      return (Number(valueA) - Number(valueB)) * direction;
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * direction;
    }
    return 0;
  });
};

export const filterData = (
  tableData: { [key: string]: string | number | Date | boolean | null }[],
  searchValue: string
) => {
  return tableData.filter((item) => {
    return Object.values(item).some((value) => {
      if (value !== null && value !== undefined) {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          return value
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        } else if (value instanceof Date) {
          return value
            .toLocaleString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
      }
      return false;
    });
  });
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const objUrl = window.URL.createObjectURL(new Blob([blob]));
      const linkTag = document.createElement("a");
      linkTag.href = objUrl;
      linkTag.setAttribute("download", fileName);
      document.body.appendChild(linkTag);
      linkTag.click();
      linkTag.parentNode?.removeChild(linkTag);
    })
    .catch((error) => console.error("Error downloading file:", error));
};

export const extractFileNameFromUrl = (url: string) => {
  const queryString = url?.split("?")[1];
  if (!queryString) {
    return "";
  }

  const params = new URLSearchParams(queryString);
  const contentDisposition = params.get("response-content-disposition");
  if (!contentDisposition) {
    return "";
  }

  const decodedContentDisposition = decodeURIComponent(contentDisposition);

  const fileNameMatch = decodedContentDisposition.match(
    /filename\*?=(UTF-8'')?([^;]+)/
  );
  if (!fileNameMatch) {
    return "";
  }

  return fileNameMatch[2].replace(/['"]/g, "");
};

export const getAmortizationEnd = (start: string, period: number): string => {
  const [startMonth, startYear] = start.split("/").map(Number);
  const totalMonths = startMonth - 2 + period;
  const endYear = Math.floor((startYear + Math.floor(totalMonths / 12)) % 100);
  const endMonth = (totalMonths % 12) + 1;

  const formattedEndMonth = endMonth.toString().padStart(2, "0");
  const formattedEndYear = endYear.toString().padStart(2, "0");

  return `${formattedEndMonth}/${formattedEndYear}`;
};

export const isValidDate = (date: string): boolean => {
  const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{2}$/;

  if (!pattern.test(date)) {
    return false;
  }

  const [month, day, year] = date.split("/").map(Number);

  const isValidDay = (month: number, day: number, year: number): boolean => {
    const daysInMonth = [
      31,
      (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return day <= daysInMonth[month - 1];
  };

  return isValidDay(month, day, year);
};

export const setFileUploadErrors = (
  errors: {
    invoice_date?: string;
    check?: string;
    invoice_number?: string;
    amortization_period?: string;
    total_amount?: string;
    expense_amount?: string;
    invoice_description?: string;
    amortization_start?: string;
    expense_account?: string;
    prepaid_account?: string;
    vendor?: string;
  }[]
) => {
  let errorMessage = "Something went wrong!";
  if (errors[0]?.invoice_date) {
    errorMessage = "Invoice Date must be in the format of MM/DD/YY";
  } else if (errors[0]?.check) {
    errorMessage = "Check value must be a number";
  } else if (errors[0]?.invoice_number) {
    errorMessage = "Invoice Number must be a number";
  } else if (errors[0]?.invoice_description) {
    errorMessage = "Invoice Description is required";
  } else if (errors[0]?.amortization_period) {
    errorMessage = "Amortization Period must be greater than or equal to one";
  } else if (errors[0]?.amortization_start) {
    errorMessage = "Amortization Start Date must be in the format of MM/YY";
  } else if (errors[0]?.total_amount) {
    errorMessage = "Total Amount must be a number";
  } else if (errors[0]?.expense_amount) {
    errorMessage = "Expense Amount must be a number";
  } else if (errors[0]?.expense_account) {
    errorMessage = "Expense Account can't be blank";
  } else if (errors[0]?.prepaid_account) {
    errorMessage = "Prepaid Account can't be blank";
  } else if (errors[0]?.vendor) {
    errorMessage = "Vendor Name can't be blank";
  }
  return errorMessage;
};

export const formatDateRange = (
  startDate: Moment | null,
  endDate: Moment | null
) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const includeYearInStart = start.year() !== end.year();

  const startFormat = includeYearInStart ? "MMMM Do, YYYY" : "MMMM Do";
  const endFormat = "MMMM Do, YYYY";

  const formattedStartDate = start.format(startFormat);
  const formattedEndDate = end.format(endFormat);

  return `From ${formattedStartDate} to ${formattedEndDate}`;
};

export const decimalRegex = /^\d*\.?\d{0,2}$/;
export const alphaNumericRegex = /^[A-Za-z0-9]+$/;
